<template>
  <div class="my-4 uploaded-images-container">
    <div
      class="mydivouter item"
      v-for="element in $store.getters.vehicleImageList"
      :key="element.ListingTagId"
    >
      <b-link :href="host + element.Value" target="_blank">
        <b-img
          thumbnail
          :src="host + element.Value"
          fluid
          :alt="element.ListingTagId.toString()"
        ></b-img>
        <p v-show="element.TagId === 167" class="text-right primary-badge">
          <span class="rounded px-1"
            ><b-icon icon="check-circle-fill" variant="success"></b-icon> {{$t('ListingProcess.PrimaryImage')}}</span
          >
        </p>
      </b-link>

      <b-btn
        variant="white"
        class="mybuttonoverlap"
        @click="$bvModal.show('confirmListingCancel' + element.ListingTagId)"
        ><b-icon-trash
      /></b-btn>
      <b-modal
        :id="'confirmListingCancel' + element.ListingTagId"
        title="Do you want delete this image"
        hide-footer
        centered
      >
        <template #default="{ hide }">
          <b-img
            :src="host + element.Value"
            blank-color="black"
            class="px-5 py-3"
            fluid-grow
          />
          <b-row class="m-2">
            <b-col
              ><b-button variant="secondary" block @click="hide()"
                >No, keep it</b-button
              ></b-col
            >
            <b-col
              ><b-button
                variant="danger"
                block
                @click="deleteImages(element.ListingTagId, element.TagId), hide()"
                >{{$t('dashboard.label.YesDeleteIt')}}</b-button
              ></b-col
            >
          </b-row>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      images: null,
      host:
        this.$store.getters.hostURL +
        "sitemedia/Images/Listings/" +
        this.$store.getters.listingStockID +
        "/",
    };
  },

  methods: {
    async deleteImages(listingTagId, tagId) {
      try {
        await axios.post("Listing/SetListing", {
          ListingId: this.$store.getters.listingStockID,
          Tags: [
            {
              ListingTagId: listingTagId,
              TagId: tagId,
              Remove: true,
            },
          ],
        });
        this.$store.dispatch(
          "vehicleImageList",
          this.$store.getters.vehicleImageList.filter(
            (e) => e.ListingTagId !== listingTagId
          )
        );
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.mydivouter {
  position: relative;
}
.mydivoverlap {
  position: relative;
  z-index: 1;
}
.mybuttonoverlap {
  position: absolute;
  z-index: 2;
  top: 8px;
  right: 8px;
  display: none;
  border-radius: 25px;
  width: 30px;
  height: 30px;
  padding: 0;
  font-size: 13px;
  svg {
    fill: #4b4b4b;
  }
}
.mydivouter:hover .mybuttonoverlap {
  display: block;
}
.uploaded-images-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 16px;
  @media (max-width: 991px) {
    column-gap: 16px;
  }
  @media (max-width: 575px) {
    column-gap: 8px;
    row-gap: 8px;
  }
  .item {
    width: calc((100% / 5) - 26px) !important;
    height: 173px !important;
    position: relative;
    @media (max-width: 991px) {
      width: calc((100% / 4) - 12px) !important;
    }
    @media (max-width: 767px) {
      height: 130px !important;
    }
    @media (max-width: 575px) {
      width: calc((100% / 4) - 6px) !important;
      height: 90px !important;
    }
    @media (max-width: 480px) {
      height: 65px !important;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border: none;
      padding: 0;
    }
  }
  .primary-badge {
    position: absolute;
    top: 8px;
    left: 8px;
    background: #4bb543;
    color: white;
    border-radius: 4px;
    svg {
      fill: white;
    }
  }
}
</style>
